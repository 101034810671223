
import './styles/quasar.sass'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/mdi-v6/mdi-v6.css'
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Dialog from 'quasar/src/plugins/Dialog.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Loading,
    LocalStorage,
    Dialog
  },
  lang
}
