import axios from 'axios'

const baseUrl = '/api/users/'

export const index = async (params = undefined) => {
  return await axios.get(baseUrl, { params })
}

export const show = async (id, params = undefined) => {
  return await axios.get(baseUrl + id, { params })
}

export const store = async (params) => {
  return await axios.post(baseUrl, params)
}

export const update = async (id, params) => {
  return await axios.put(baseUrl + id, params)
}

export const destroy = async (id) => {
  return await axios.delete(baseUrl + id)
}
