import {
  index as _index,
  show as _show,
  store as _store,
  update as _update,
  destroy as _destroy,
  xls as _xls,
  takeStockXls as _takeStockXls,
  takeStockStats as _takeStockStats,
  indexXls as _indexXls
} from '@/api/Passport/service-objects'
import useCRUD from '@/composables/Common/useCRUD'

export default () => {
  const {
    errorMessages,
    item,
    items,
    prepairRules,
    loading,
    index: crudIndex,
    show: crudShow,
    store: crudStore,
    update: crudUpdate,
    destroy: crudDestroy
  } = useCRUD()

  const alias = 'ServiceObject'

  item.value = {
    title: '',
    isDemounted: false,
    isCommissioned: false,
    isOutOfService: false,
    divisions: [],
    place: {
      location: {
        type: 'Point',
        coordinates: []
      },
      props: {},
      placeableType: 'App\\Models\\Location\\Road'
    },
    reason: null,
    dataSourceConnection: {
      props: {}
    },
    revision: {
      props: {},
      components: [],
      sharedComponents: []
    }
  }

  prepairRules.value = {
    divisionIds: () => item.value.divisions.map(division => division.id)
  }

  const rules = {}

  const index = (params = undefined, type = undefined) => {
    return crudIndex(params, type, _index)
  }

  const show = (id = undefined, params = undefined) => {
    return crudShow(id, params, _show)
  }

  const xls = (id = undefined, params = undefined) => {
    _xls(id, params).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'passport.xlsx')
      document.body.appendChild(link)
      link.click()
    })
      .catch(e => { console.error(e) })
  }

  const indexXls = (id = undefined, params = undefined) => {
    _indexXls(id, params).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'passports.xlsx')
      document.body.appendChild(link)
      link.click()
    })
      .catch(e => { console.error(e) })
  }

  const takeStockXls = (params = undefined) => {
    _takeStockXls(params).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'inventarization.xlsx')
      document.body.appendChild(link)
      link.click()
    })
      .catch(e => { console.error(e) })
  }

  const store = (params) => {
    return crudStore(params, _store)
  }

  const update = (params) => {
    return crudUpdate(params, _update)
  }

  const destroy = (item) => {
    return crudDestroy(item, _destroy)
  }

  const takeStockStats = (params = undefined, type = undefined) => {
    return crudIndex(params, type, _takeStockStats)
  }

  return {
    alias,
    errorMessages,
    items,
    item,
    loading,
    rules,
    index,
    show,
    store,
    update,
    destroy,
    indexXls,
    takeStockXls,
    takeStockStats,
    xls
  }
}
