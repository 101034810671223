import { ref } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default () => {
  const errors = ref({})
  const errorMessages = ref({})
  const item = ref({})
  const items = ref([])
  const links = ref({})
  const loading = ref(false)
  const meta = ref({})
  const prepairRules = ref({})

  const $q = useQuasar()

  const index = (params = undefined, type = undefined, crudFunc) => {
    loading.value = true

    crudFunc(params, type).then(response => {
      items.value = response.data.data
      meta.value = response.data.meta || {
        total: response.data.data.length,
        per_page: 0,
        current_page: 1
      }
      links.value = response.data.links || {}
      loading.value = false
    }).catch(e => {
      errors.value = e.response
      loading.value = false
      notify('Ошибка ' + e.response.status + '. ' + e.response.data.message)
    })

    return {
      items,
      meta,
      links,
      loading,
      errors
    }
  }

  const show = (id = undefined, params = undefined, crudFunc) => {
    const loading = ref(true)

    if (id) {
      crudFunc(id, params).then(response => {
        item.value = response.data.data
        loading.value = false
      }).catch(e => {
        errors.value = e.response
        notify('Ошибка ' + e.response.status + '. ' + e.response.data.message)
        loading.value = false
      })
    }

    return {
      item,
      loading,
      errors
    }
  }

  const store = (params, crudFunc) => {
    const loading = ref(true)
    const status = ref(0)

    errors.value = {}
    prepair(params)

    crudFunc(params).then(response => {
      status.value = response.status
      item.value = response.data.data
      loading.value = false
    }).catch(e => {
      status.value = e.response.status
      errors.value = e.response
      if (e.response.status === 422) {
        notify('Проверьте корректность введенных данных', false)
      } else {
        notify('Ошибка ' + e.response.status + '. ' + e.response.data.message)
      }
      errorMessages.value = e.response.data.errors
      loading.value = false
    })

    return {
      item,
      status,
      loading,
      errors,
      errorMessages
    }
  }

  const update = (params, crudFunc) => {
    const loading = ref(true)
    const status = ref(0)

    errors.value = {}
    prepair(params)

    crudFunc(params.id, params).then(response => {
      status.value = response.status
      item.value = response.data.data
      loading.value = false
    }).catch(e => {
      status.value = e.response.status
      errors.value = e.response
      if (e.response.status === 422) {
        notify('Проверьте корректность введенных данных', false)
      } else {
        notify('Ошибка ' + e.response.status + '. ' + e.response.data.message)
      }
      errorMessages.value = e.response.data.errors
      loading.value = false
    })

    return {
      item,
      status,
      loading,
      errors,
      errorMessages
    }
  }

  const destroy = (item, crudFunc) => {
    const loading = ref(true)
    const status = ref(0)

    errors.value = {}

    crudFunc(item.id).then(response => {
      status.value = response.status
      item.value = response.data.data
      loading.value = false
    }).catch(e => {
      status.value = e.response.status
      errors.value = e.response
      if (e.response.status !== 422) {
        notify('Ошибка ' + e.response.status + '. ' + e.response.data.message)
      } else if (e.response.data.errors) {
        notify(e.response.data.errors.notify)
      }
      errorMessages.value = e.response.data.errors
      loading.value = false
    })

    return {
      item,
      status,
      loading,
      errors,
      errorMessages
    }
  }

  const prepair = (params) => {
    Object.entries(prepairRules.value).forEach(([prop, rule]) => {
      params[prop] = rule(params[prop])
    })
  }

  const notify = (message, copy = true) => {
    $q.notify({
      color: 'negative',
      message,
      position: 'top-right',
      actions: copy
        ? [
          { icon: 'content_copy', color: 'white', handler: () => { navigator.clipboard.writeText(message) } }
        ]
        : null,
      progress: true
    })
  }

  return {
    errors,
    errorMessages,
    item,
    items,
    links,
    loading,
    meta,
    prepairRules,
    index,
    notify,
    show,
    store,
    update,
    destroy
  }
}
