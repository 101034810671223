import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from './services/ability'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
// import QCalendarPlugin from '@quasar/quasar-ui-qcalendar/src/QCalendarDay.js'

import QCalendarPlugin from '@quasar/quasar-ui-qcalendar/src/QCalendarDay.js'
import '@quasar/quasar-ui-qcalendar/src/css/calendar-day.sass'


const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(QCalendarPlugin)
  .use(store)
  .use(abilitiesPlugin, ability, { useGlobalProperties: true })
  .directive('click-outside', {
    beforeMount (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })

// Sentry.init({
//   app,
//   dsn: 'https://677d805c42bf450b8a41f429cb43ba4d@o1134259.ingest.sentry.io/6181559',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['s.xologie.ru', 'www.s.xologie.ru', /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

app.use(router)
app.mount('#app')

export {
  app
}
