import {
  auth as _auth,
  getUser as _getUser,
  logout as _logout
} from '@/api/Common/auth'

import { ability, defineAbilityFor } from '@/services/ability'
import router from '@/router/index'

const auth = async (context, params) => {
  const response = await _auth(params)
  context.commit('AUTH', response.data)
  ability.update(defineAbilityFor(context.getters.user).rules)
  router.push('Home')
}

const getUser = async (context) => {
  const response = await _getUser()
  context.commit('AUTH', response.data)
  ability.update(defineAbilityFor(context.getters.user).rules)
}

const logout = async (context) => {
  await _logout()
  context.commit('AUTH', undefined)
  router.push('Login')
}

export default {
  auth,
  getUser,
  logout
}
