<template><div v-show="false"></div></template>

<script setup>
import { inject, defineProps } from 'vue'
import jsSha256 from 'js-sha256';

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
}) 

const onLoad = inject('onLoad')
const setAttributes = inject('setAttributes')
const visitor = inject('visitor');

onLoad(() => {
  setAttributes({
    id: props.user.id,
    name: props.user.name,
    email: props.user.email,
    hash: jsSha256.hmac(props.user.tawk.propertyId, props.user.email)
  })

  console.log({
    id: props.user.id,
    name: props.user.name,
    email: props.user.email,
    hash: jsSha256.hmac(props.user.tawk.propertyId, props.user.email)
  })
})
</script>