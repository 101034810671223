import script from "./Navigation.vue?vue&type=script&setup=true&lang=js"
export * from "./Navigation.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QList,QExpansionItem,QItemLabel,QItem,QItemSection,QIcon,QAvatar,QSeparator});qInstall(script, 'directives', {Ripple});
