const define = (extentions, roles, can, cannot) => {
  if (extentions.includes('kpi')) {
    if (roles.includes('root')) {
      can('manage', 'Kpi')
      can('manage', 'KpiMap')
      can('manage', 'UserMapResults')
      can('manage', 'UserMap')
    }

    if (roles.includes('manager')) {
      can('manage', 'Kpi')
      can('manage', 'KpiMap')
      can('manage', 'UserMapResults')
      can('manage', 'UserMap')
    }

    if (roles.includes('disp')) {
      can('manage', 'Kpi')
      can('manage', 'KpiMap')
      can('manage', 'UserMapResults')
      can('manage', 'UserMap')
    }
  } else {
    cannot('manage', 'Kpi')
    cannot('manage', 'KpiMap')
  }
}
export default {
  define
}
