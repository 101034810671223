const define = (extentions, roles, can, cannot) => {
  if (extentions.includes('crm')) {
    if (roles.includes('root')) {
      can('manage', 'CrmMode')
      can('manage', 'CrmInbox')
      can('manage', 'CrmDescription')
    }
    if (roles.includes('crmSeller')) {
      can('manage', 'CrmMode')
      can('manage', 'Inbox')
      can('manage', 'CrmInbox')
      can('manage', 'CrmDescription')
    }
  } else {
    cannot('manage', 'CrmMode')
    cannot('manage', 'CrmInbox')
    cannot('manage', 'CrmDescription')
  }
}
export default {
  define
}
