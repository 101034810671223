<template>
  <q-scroll-area class="fit">
    <q-list class="rounded-borders text-grey-8 text-weight-medium">
      <div v-for="(section, i) in menu" :key="i">
        <q-expansion-item
          :icon="section.icon"
          :label="section.label"
          expand-separator
          v-if="canRead(section)"
        >
          <div v-for="(item, j) in section.items" :key="j">
            <q-item-label
              v-if="item.header && canRead(item)"
              class="q-ml-md"
              header
            >
              {{ item.header }}
            </q-item-label>
            <q-item
              v-else-if="canRead(item)"
              class="q-pl-xl"
              active-class="bg-blue-grey-1 text-primary"
              :to="item.url"
              @click="item.click ? item.click() : null"
              clickable
              v-ripple
            >
              <q-item-section v-if="item.icon" avatar>
                <q-icon :name="item.icon" />
              </q-item-section>
              <q-item-section v-if="item.img" avatar>
                <q-avatar size="sm">
                  <img :src="item.img" style="filter: brightness(40%)" />
                </q-avatar>
              </q-item-section>
              <q-item-section>{{ item.label }}</q-item-section>
            </q-item>
          </div>
        </q-expansion-item>
      </div>

      <q-separator />

      <q-item
        clickable
        v-ripple
        :href="
          'https://t.me/s_xologie_bot?start=' + user?.telegram?.botAuthCode
        "
        target="_blank"
      >
        <q-item-section avatar>
          <q-icon name="smart_toy" />
        </q-item-section>
        <q-item-section>Авторизовать бот</q-item-section>
      </q-item>

      <q-item clickable v-ripple @click="logout">
        <q-item-section avatar>
          <q-icon name="logout" />
        </q-item-section>
        <q-item-section>Выход</q-item-section>
      </q-item>
    </q-list>
  </q-scroll-area>
</template>

<script setup>
import { useStore } from "vuex";
import useObjectTypes from "@/composables/Passport/useObjectTypes";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import useUsers from "@/composables/Admin/useUsers";
import useServiceObjects from "@/composables/Passport/useServiceObject";
import { useAbility } from "@casl/vue";

const menu = ref([]);
const store = useStore();

const { index: getObjectTypes } = useObjectTypes();
const { items: objectTypes } = getObjectTypes();

const { can } = useAbility();

watch(objectTypes, () => {
  menu.value = [
    {
      label: "Мониторинг",
      icon: "tv",
      items: [
        { label: "Гис", icon: "location_on", url: "/" },
        // { label: 'Качество', icon: 'analytics', url: '/quality' }
      ],
    },
    {
      label: "Сервис деск",
      icon: "engineering",
      rights: "ServiceDesk",
      items: [
        { label: "Задачи", icon: "task_alt", url: "/tasks", rights: "Task" },
        {
          label: "Приемка",
          icon: "verified",
          url: "/acceptance",
          rights: "Acceptance",
        },
        {
          label: "Инциденты",
          icon: "report_problem",
          url: "/incidents",
          rights: "Incident",
        },
        {
          label: "Рекламации",
          icon: "error_outline",
          url: "/reclamations",
          rights: "Reclamation",
        },
        {
          label: "Экспертиза Инцидентов",
          icon: "science",
          url: "/expert-incidents",
          rights: "ExpertIncident",
        },
        { header: "Отчеты" },
        {
          label: "Выполнение",
          icon: "article",
          url: "/fulfillment",
          rights: "Fulfillment",
        },
        {
          label: "По задачам",
          icon: "rules",
          url: "/tasks-report",
          rights: "Fulfillment",
        },
        {
          label: "Выгрузка фотоотчетов",
          icon: "cloud_download",
          url: "/image-report",
          rights: "Fulfillment",
        },
        {
          label: "Структура неисправностей",
          icon: "data_usage",
          url: "/failures",
          rights: "Incident",
        },
        {
          label: "Диаграмма сгорания",
          icon: "area_chart",
          url: "/burndown",
          rights: "Task",
        },
      ],
    },
    {
      label: "KPI",
      icon: "leaderboard",
      rights: "Kpi",
      items: [
        {
          label: "Карты KPI",
          icon: "table_view",
          url: "/kpi-maps",
          rights: "KpiMap",
        },
        {
          label: "Карты сотрудников",
          icon: "table_view",
          url: "/kpi-user-maps",
          rights: "KpiMap",
        },
        {
          label: "Результаты сотрудников",
          icon: "table_view",
          url: "/kpi-user-results",
          rights: "KpiMap",
        },
      ],
    },
    {
      label: "Паспортизация",
      icon: "fact_check",
      rights: "Passport",
      items: [
        ...objectTypes.value.map((type) => ({
          label: type.title,
          url: "/passport/" + type.alias + "/",
          img: type.icon,
          rights: "Passport",
        })),
        {
          label: "Инвентаризация",
          url: "/take-stock/",
          icon: "inventory_2",
          rights: "Passport",
        },
        {
          label: "Гарантийные обязательства",
          url: "/warranty-obligations/",
          icon: "lock_clock",
          rights: "Passport",
        },
        {
          label: "Заметки",
          url: "/notes/",
          icon: "note",
          rights: "Passport",
        },
        {
          label: "Экспортировать",
          url: null,
          click: passportXls,
          icon: "download",
          rights: "Passport",
        },
      ],
    },
    {
      label: "CRM",
      icon: "business_center",
      rights: "CRM",
      items: [
        { label: "Входящие", icon: "inbox", url: "/inbox", rights: "Inbox" },
        {
          label: "Приемка/Снятия",
          icon: "price_change",
          url: "/customer-report",
          rights: "CustomerReport",
        },
        {
          label: "Работоспособность посуточно",
          icon: "date_range",
          url: "/regular-daily",
          rights: "CustomerReport",
        },
        {
          label: "Исполнение контракта",
          icon: "handshake",
          url: "/contract-fulfillment",
          rights: "CustomerReport",
        },
        { header: "Справочники" },
        {
          label: "Организации",
          icon: "work",
          url: "/customers",
          rights: "Admin",
        },
        {
          label: "Клиенты",
          icon: "emoji_people",
          url: "/clients",
          rights: "Client",
        },
        {
          label: "Категории входящих",
          icon: "folder",
          url: "/inbox-types",
          rights: "Admin",
        },
        // { label: 'Исполнители', icon: 'handyman', url: '/executors', rights: 'Client' },
        {
          label: "Контракты",
          icon: "handshake",
          url: "/contracts",
          rights: "Client",
        },
      ],
    },
    {
      label: "Администрирование",
      icon: "settings",
      rights: "Admin",
      items: [
        { header: "Доступ и сервис" },
        {
          label: "Пользователи",
          icon: "person",
          url: "/users",
          rights: "Admin",
        },
        {
          label: "Подразделения",
          icon: "groups",
          url: "/divisions",
          rights: "Admin",
        },
        { label: "Бригады", icon: "people", url: "/teams", rights: "Admin" },
        // { label: 'Организации', icon: 'work', url: '/customers', rights: 'Admin' },
        // { label: 'Операторы', icon: 'work_outline', url: '/operators', rights: 'Admin' },

        { header: "Паспортизация", rights: "Admin" },
        {
          label: "Типы компонентов",
          icon: "fiber_manual_record",
          url: "/component-types",
          rights: "Admin",
        },
        {
          label: "Типы объектов",
          icon: "group_work",
          url: "/object-types",
          rights: "Admin",
        },
        {
          label: "Причины ревизий",
          icon: "copy_all",
          url: "/revision-reasons",
          rights: "Admin",
        },
        {
          label: "Обновляемые компоненты",
          icon: "copy_all",
          url: "/updatable-models",
          rights: "Admin",
        },

        { header: "Адресация", rights: "Admin" },
        { label: "Автодороги", icon: "route", url: "/roads", rights: "Admin" },
        {
          label: "Железные дороги",
          icon: "train",
          url: "/railways",
          rights: "Admin",
        },
        {
          label: "Кадастровые участки",
          icon: "map",
          url: "/cadastrals",
          rights: "Admin",
        },
        { label: "Локации", icon: "place", url: "/places", rights: "Admin" },

        { header: "Сервис Деск", rights: "Admin" },
        {
          label: "Инциденты",
          icon: "report_problem",
          url: "/incident-types",
          rights: "Admin",
        },
        {
          label: "Типы рекламаций",
          icon: "error_outline",
          url: "/reclamation-types",
          rights: "ReclamationType",
        },
        {
          label: "Неисправности",
          icon: "bug_report",
          url: "/failure-groups",
          rights: "Admin",
        },
        {
          label: "Регламентные работы",
          icon: "event",
          url: "/regular-types",
          rights: "Admin",
        },
        {
          label: "Группы технологических операций",
          icon: "checklist",
          url: "/operation-groups",
          rights: "Admin",
        },
        {
          label: "Технологические операции",
          icon: "checklist",
          url: "/operations",
          rights: "Admin",
        },
        {
          label: "Единицы измерения",
          icon: "category",
          url: "/operation-units",
          rights: "Admin",
        },
      ],
    },
  ];
});

const logout = () => {
  store.dispatch("auth/logout");
  window.location.reload();
};

const userId = store.getters["auth/user"].id;
const { show: getUser } = useUsers();

const { item: user } = getUser(userId, { load: "telegram" });

const canRead = (item) =>
  !item.rights || (item.rights && can("read", item.rights));

const { indexXls } = useServiceObjects();

const passportXls = () => {
  indexXls();
};
</script>

<style></style>
