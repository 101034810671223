import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/quality',
    name: 'Quality',
    component: () => import('@/views/Quality.vue'),
    meta: { requiresAuth: true }
  },

  // ServiceDesk

  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('@/views/ServiceDesk/Inbox.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/views/ServiceDesk/Tasks.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/acceptance',
    name: 'Acceptance',
    component: () => import('@/views/ServiceDesk/Acceptance.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/incidents',
    name: 'Incidents',
    component: () => import('@/views/ServiceDesk/Incidents.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reclamations',
    name: 'Reclamations',
    component: () => import('@/views/ServiceDesk/Reclamations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/expert-incidents',
    name: 'ExpertIncidents',
    component: () => import('@/views/ServiceDesk/ExpertIncidents.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulfillment',
    name: 'Fulfillment',
    component: () => import('@/views/ServiceDesk/Fulfillment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks-report',
    name: 'TasksReport',
    component: () => import('@/views/ServiceDesk/TasksReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customer-report',
    name: 'CustomerReport',
    component: () => import('@/views/CRM/CustomerReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/regular-daily',
    name: 'RegularDaily',
    component: () => import('@/views/CRM/RegularDaily.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contract-fulfillment',
    name: 'ContractFulfillment',
    component: () => import('@/views/CRM/ContractFulfillment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks-calendar',
    name: 'TasksCalendar',
    component: () => import('@/views/ServiceDesk/TasksCalendar.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/failures',
    name: 'Failures',
    component: () => import('@/views/ServiceDesk/Failures.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/burndown',
    name: 'Burndown',
    component: () => import('@/views/ServiceDesk/Burndown.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/image-report',
    name: 'ImageReport',
    component: () => import('@/views/ServiceDesk/ImageReport.vue'),
    meta: { requiresAuth: true }
  },

  /// KPI

  {
    path: '/kpi-maps',
    name: 'KpiMaps',
    component: () => import('@/views/Kpi/KpiMaps.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/kpi-user-maps',
    name: 'KpiUserMaps',
    component: () => import('@/views/Kpi/UserMaps.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/kpi-user-results',
    name: 'KpiUserResults',
    component: () => import('@/views/Kpi/UserResults'),
    meta: { requiresAuth: true }
  },

  // Passportization

  {
    path: '/passport/:type',
    name: 'Passport',
    component: () => import('@/views/Passport.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/take-stock',
    name: 'TakeStock',
    component: () => import('@/views/TakeStock.vue'),
    meta: { requiresAuth: true }
  },


  {
    path: '/notes',
    name: 'Notes',
    component: () => import('@/views/Passport/Notes.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/warranty-obligations',
    name: 'WarrantyObligations',
    component: () => import('@/views/Passport/WarrantyObligations.vue'),
    meta: { requiresAuth: true }
  },

  // Admin
  // // Access && Service
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Admin/AccessService/Users.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/divisions',
    name: 'Divisions',
    component: () => import('@/views/Admin/AccessService/Divisions.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/executors',
    name: 'Executors',
    component: () => import('@/views/Admin/AccessService/Executors.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('@/views/Admin/AccessService/Contracts.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('@/views/Admin/AccessService/Teams.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('@/views/Admin/AccessService/Customers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('@/views/Admin/AccessService/Clients.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/operators',
  //   name: 'Operators',
  //   component: () => import('@/views/Admin/AccessService/Operators.vue'),
  //   meta: { requiresAuth: true }
  // },

  // // Passport
  {
    path: '/object-types',
    name: 'ObjectTypes',
    component: () => import('@/views/Admin/Passport/ObjectTypes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/revision-reasons',
    name: 'RevisionReasons',
    component: () => import('@/views/Admin/Passport/RevisionReasons.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/component-types',
    name: 'ComponentTypes',
    component: () => import('@/views/Admin/Passport/ComponentTypes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/updatable-models',
    name: 'UpdatableModels',
    component: () => import('@/views/Firmware/UpdatableModels.vue'),
    meta: { requiresAuth: true }
  },

  // // Locations
  {
    path: '/roads',
    name: 'Roads',
    component: () => import('@/views/Admin/Locations/Roads.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/railways',
    name: 'Railways',
    component: () => import('@/views/Admin/Locations/Railways.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cadastrals',
    name: 'Cadastrals',
    component: () => import('@/views/Admin/Locations/Cadastrals.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import('@/views/Admin/Locations/Places.vue'),
    meta: { requiresAuth: true }
  },

  // // ServiceDesk
  {
    path: '/incident-types',
    name: 'IncidentTypes',
    component: () => import('@/views/Admin/ServiceDesk/IncidentTypes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/inbox-types',
    name: 'InboxTypes',
    component: () => import('@/views/Admin/ServiceDesk/InboxTypes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/failure-groups',
    name: 'FailureGroups',
    component: () => import('@/views/Admin/ServiceDesk/FailureGroups.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/regular-types',
    name: 'RegularTypes',
    component: () => import('@/views/Admin/ServiceDesk/RegularTypes.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reclamation-types',
    name: 'ReclamationTypes',
    component: () => import('@/views/Admin/ServiceDesk/ReclamationTypes.vue'),
    meta: { requiresAuth: true }
  },
    {
    path: '/operation-groups',
    name: 'OperationGroups',
    component: () => import('@/views/Admin/ServiceDesk/OperationGroups.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/operations',
    name: 'Operations',
    component: () => import('@/views/Admin/ServiceDesk/Operations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/operation-units',
    name: 'OperationUnits',
    component: () => import('@/views/Admin/ServiceDesk/OperationUnits.vue'),
    meta: { requiresAuth: true }
  },

  // Authentication
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      hideNav: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) { // requires Auth
    if (!store.getters['auth/user']) {
      store.dispatch('auth/getUser').then(() => {
        if (!store.getters['auth/user']) {
          console.log('not auth')
          next('/login')
        } else {
          // if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
          //   next('/403')
          // }
          next()
        }
      }).catch(() => {
        window.location.href = '/login'
      })
    } else {
      // if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
      //   next('/403')
      // }
      next()
    }
  } else { // no Auth
    if (store.getters['auth/user']) {
      next('/')
    } else {
      store.dispatch('auth/getUser').then(() => {
        if (store.getters['auth/user']) {
          next('/')
        } else {
          next()
        }
      }).catch(() => {
        next()
      })
    }
  }
})

export default router
