import { AbilityBuilder, Ability } from '@casl/ability'
import crmAbilities from './crmAbilities'
import kpiAbilities from './kpiAbilities'

const ability = new Ability()

const defineAbilityFor = (user) => {
  const { rules, can, cannot } = new AbilityBuilder(Ability)

  const roles = user.roles.map(el => el.alias)

  const extentions = user.account.extentions.map(ext => ext.alias)

  if (roles.includes('root')) {
    can('manage', 'all')
  }

  if (roles.includes('admin')) {
    can('manage', 'Admin')
    can('manage', 'Team')
    can('manage', 'Customer')
    can('manage', 'Client')
    can('manage', 'Division')
    can('manage', 'Operator')
    can('manage', 'User')
    can('manage', 'Team')
    can('manage', 'Road')
    can('manage', 'Place')
    can('manage', 'Contract')
    can('manage', 'Operation')
    can('manage', 'OperationGroup')
    can('manage', 'OperationUnit')
    can('manage', 'ComponentType')
    can('manage', 'AssignmentType')
    can('manage', 'FailureGroup')
    can('manage', 'IncidentType')
    can('manage', 'ReclamationType')
    can('manage', 'RegularType')
    can('manage', 'ObjectType')
    can('manage', 'InboxType')
    can('manage', 'RevisionReason')
    can('manage', 'Cadastral')
    can('read', 'Filter')
    can('read', 'Gallery')
  }

  if (roles.includes('passportizator')) {
    can('manage', 'Passport')
    can('read', 'Filter')
    can('manage', 'ServiceObject')
  }

  if (roles.includes('manager')) {
    can('read', 'Incident')
    can('create', 'Incident')
    can('update', 'Incident')
    can('manage', 'Contract')
    can('manage', 'Task')
    can('manage', 'Reclamation')
    can('manage', 'Note')
    can('manage', 'Client')
    can('manage', 'Fulfillment')
    can('manage', 'ServiceDesk')
    can('manage', 'Acceptance')
    can('manage', 'Regulars')
    can('manage', 'Assignments')
    can('read', 'Filter')
    can('read', 'Gallery')
    can('manage', 'CRM')
    can('manage', 'CustomerReport')
    can('manage', 'TaskTimer')
  }

  if (roles.includes('expert')) {
    can('manage', 'ExpertIncident')
    can('manage', 'Reclamation')
  }

  if (roles.includes('disp')) {
    can('manage', 'Incident')
    can('manage', 'Client')
    can('manage', 'Note')
    can('manage', 'Task')
    can('manage', 'Inbox')
    can('read', 'Fulfillment')
    can('read', 'ServiceDesk')
    can('read', 'Regulars')
    can('read', 'Assignments')
    can('read', 'Filter')
    can('read', 'Gallery')
    can('read', 'CRM')
    can('manage', 'CustomerReport')
    can('manage', 'TaskTimer')
    can('manage', 'Reclamation')
    can('manage', 'ReportOperation')
  }

  if (roles.includes('servicer')) {
    can('read', 'Task')
    can('create', 'Task')
    can('read', 'Reclamation')
    can('create', 'Reclamation')
    can('update', 'Reclamation')
    can('manage', 'Report')
    can('manage', 'Note')
    can('read', 'Incident')
    can('create', 'Incident')
    can('update', 'Incident')
    can('read', 'ServiceDesk')
    can('manage', 'Fulfillment')
    can('read', 'Gallery')
    can('read', 'Filter')
  }

  crmAbilities.define(extentions, roles, can, cannot)
  kpiAbilities.define(extentions, roles, can, cannot)

  return new Ability(rules)
}

export {
  ability,
  defineAbilityFor
}
