import { required, email } from '@vuelidate/validators'
import {
  index as _index,
  show as _show,
  store as _store,
  update as _update,
  destroy as _destroy
} from '@/api/Admin/users'
import useCRUD from '@/composables/Common/useCRUD'

export default () => {
  const {
    errorMessages,
    item,
    items,
    prepairRules,
    index: crudIndex,
    show: crudShow,
    store: crudStore,
    update: crudUpdate,
    destroy: crudDestroy
  } = useCRUD()

  const alias = 'User'

  item.value = {
    division: null,
    email: '',
    name: '',
    password: '',
    phone: '',
    usesPersonalTransport: false,
    roles: []
  }

  prepairRules.value = {
    roles: val => val.map(item => item.id),
    divisionId: () => item.value.division?.id
  }

  const rules = {
    email: [
      val => required.$validator(val) || 'Введите адрес электронной почты',
      val => email.$validator(val) || 'Введите корректный адрес'
    ],
    name: [val => required.$validator(val) || 'Введите имя пользователя'],
    password: [val => (item.value.id ? true : required.$validator(val)) || 'Введите пароль'],
    roles: [val => required.$validator(val) || 'Укажите хотя бы одну роль']
  }

  const index = (params = undefined, type = undefined) => {
    return crudIndex(params, type, _index)
  }

  const show = (id = undefined, params = undefined) => {
    return crudShow(id, params, _show)
  }

  const store = (params) => {
    return crudStore(params, _store)
  }

  const update = (params) => {
    return crudUpdate(params, _update)
  }

  const destroy = (item) => {
    return crudDestroy(item, _destroy)
  }

  return {
    alias,
    errorMessages,
    item,
    items,
    rules,
    index,
    show,
    store,
    update,
    destroy
  }
}
