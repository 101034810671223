import axios from 'axios'

const baseUrl = '/api/service-objects/'

export const index = async (params = undefined, type = undefined) => {
  return await axios.get(type ? baseUrl + type : baseUrl, { params })
}

export const show = async (id, params = undefined) => {
  return await axios.get(baseUrl + id, { params })
}

export const xls = async (id, params = undefined) => {
  return await axios({
    method: 'GET',
    url: baseUrl + id + '/xls',
    params,
    responseType: 'blob'
  })
}

export const indexXls = async (id, params = undefined) => {
  return await axios({
    method: 'GET',
    url: baseUrl + 'xls',
    params,
    responseType: 'blob'
  })
}

export const takeStockXls = async (params = undefined) => {
  return await axios({
    method: 'GET',
    url: baseUrl + 'take-stock',
    params,
    responseType: 'blob'
  })
}

export const store = async (params) => {
  return await axios.post(baseUrl, params)
}

export const update = async (id, params) => {
  return await axios.put(baseUrl + id, params)
}

export const destroy = async (id) => {
  return await axios.delete(baseUrl + id)
}

export const byLocation = async (params) => {
  return await axios.get(baseUrl + 'by-location', { params })
}

export const takeStockStats = async (params = undefined) => {
  return await axios.get(baseUrl + 'take-stock-stats/', { params })
}
