import {
  index as _index,
  show as _show,
  store as _store,
  update as _update,
  destroy as _destroy
} from '@/api/Passport/object-types'
import useCRUD from '@/composables/Common/useCRUD'

export default () => {
  const {
    errorMessages,
    item,
    items,
    prepairRules,
    index: crudIndex,
    show: crudShow,
    store: crudStore,
    update: crudUpdate,
    destroy: crudDestroy
  } = useCRUD()

  const alias = 'ObjectType'

  item.value = {
    title: '',
    description: '',
    requiresVerification: false,
    componentTypes: [],
    icon: '',
    color: '',
    attrs: []
  }

  prepairRules.value = {}

  const rules = {}

  const index = (params = undefined, type = undefined) => {
    return crudIndex(params, type, _index)
  }

  const show = (id = undefined, params = undefined) => {
    return crudShow(id, params, _show)
  }

  const store = (params) => {
    return crudStore(params, _store)
  }

  const update = (params) => {
    return crudUpdate(params, _update)
  }

  const destroy = (item) => {
    return crudDestroy(item, _destroy)
  }

  return {
    alias,
    errorMessages,
    rules,
    item,
    items,
    index,
    show,
    store,
    update,
    destroy
  }
}
