<template>
  <q-layout
    view="hHh Lpr lff"
    v-if="$route.matched.length"
  >
    <q-header
      elevated
      class="bg-secondary text-white"
      v-if="!$route.meta.hideNav"
    >
      <q-toolbar>
        <q-btn
          dense
          flat
          round
          icon="menu"
          @click="toggleLeftDrawer"
        />

        <q-toolbar-title>
          <span class="text-orange">X</span>.OLOGIE·S
          <!-- <img
            class="q-mt-sm"
            src="images/logo.png"
          /> -->
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer
      show-if-above
      elevated
      v-model="leftDrawerOpen"
      side="left"
      :width="280"
      v-if="!$route.meta.hideNav"
    >
      <navigation />
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
    <twak :user="user" v-if="showTwak" />
  </q-layout>
</template>

<script setup>
import { ref, watch, computed, inject } from 'vue'
import {app} from './main'
import store from '@/store/index.js'
import Navigation from './components/Common/Navigation'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import Twak from '@/components/Common/Twak'

const user = computed(() => store.getters['auth/user'])

const showTwak = ref(false)

watch(user, () => {
  if (user.value?.tawk) {
    app.use(TawkMessengerVue, {
      propertyId : user.value.tawk.propertyId,
      widgetId : user.value.tawk.widgetId
    })

    showTwak.value = true
  }
})

const leftDrawerOpen = ref(false)

const toggleLeftDrawer = () =>  {
  leftDrawerOpen.value = !leftDrawerOpen.value
}
</script>
