import axios from 'axios'

export const auth = async (params) => {
  return await axios.post('/api/auth/', params)
}

export const getUser = async () => {
  return await axios.get('/api/user/')
}

export const logout = async () => {
  return await axios.post('/api/logout/')
}
